import React, {useEffect, useMemo, useState} from "react";
import {Alert, Button, Col, Form, Modal, Row, Table} from "react-bootstrap";
import {buildDateFromStr, ErrorFallback, sendNotification} from "../commons";
import {ErrorBoundary} from "react-error-boundary";
import HorizontalLine from "../common/HorizontalLine";
import {trackPromise} from "react-promise-tracker";
import {
    BiWater,
    BsTable,
    FaGasPump,
    FaRecycle,
    GiElectric,
    GoCalendar,
    GrServerCluster,
    GrTechnology,
    SiMarketo
} from "react-icons/all";
import SimulationProgressMessages from "./SimulationProgressMessages";
import {useHistory} from "react-router-dom";
import SimulationWarnings from "./SimulationWarnings";
import CreatableSelect from "react-select/creatable";

function invalidUnitDates(dateFrom, dateTo) {
    if(dateFrom !== '' && dateTo !== '') {
        let fromDate = buildDateFromStr(dateFrom);
        let toDate = buildDateFromStr(dateTo);
        return fromDate > toDate;
    }
    return false;
}

function getDefaultByType(type, dataSeries) {
    let dataSeriesType = dataSeries.filter(d=>d.type === type)[0];
    let defaultByType = dataSeriesType.files.filter(f=>f.is_default);
    return defaultByType.length === 1 ? defaultByType[0].id : (dataSeriesType.files.length > 0 ? dataSeriesType.files[0].id : "");
}

function buildUnits(units) {
    let counter = 1;
    let results = [];
    units.forEach(i => {
        if(i["units"].length > 0){
            i["units"].forEach(u =>{
                results.push({
                    key: counter,
                    id: u["id"],
                    code: u["code"],
                    company: i["company_name"],
                    technology: i["technology"],
                    dateOn: u["start_date_fmt"],
                    dateOff: u["end_date_fmt"],
                    updated: false
                });
                counter++;
            })
        }
    });
    return results;
}

function buildFirmsData(data) {
    return data.reduce((a, v) => ({ ...a, [v["code"]]: {
            price: 0,
            quantity: 0,
            strategy: v["strategy"] === 1
        }}), {});
}

/**
 * @return {null}
 */
function SimulationConfigForm({simulation, dataSeries,
                                  marketStructureList,
                                  userId,
                                  finishSimulation,
                                  updateSimulation,
                                  updateSimulationList,
                                  cancelSimulationChanges,
                                  loadSimulation,
                                  deleteSimulationModal,
                                  username,
                                  readOnly=false}) {

    let history = useHistory();

    let defaultGasId = getDefaultByType("fuel", dataSeries);
    let defaultRenewableId = getDefaultByType("renewable", dataSeries);
    let defaultDemandId = getDefaultByType("demand", dataSeries);
    let defaultInterconnectionId = getDefaultByType("interconnection", dataSeries);
    let defaultMaintenanceId = getDefaultByType("maintenance", dataSeries);
    let defaultHydroId = getDefaultByType("hydro", dataSeries);
    let defaultMarketStructureId = marketStructureList[0]["value"];

    const defaultSimulationConfig = useMemo(() => ({
        id: simulation.id,
        name: "",
        tag: "",
        companyCode: "",
        simulationType: "energeia-simula",
        projectType: "",
        timePeriod: {
            mode: "General",
            dateFrom: "",
            dateTo: "",
            month: "",
            year: ""
        },
        marketStructureId: defaultMarketStructureId,
        demand:{
            id: defaultDemandId,
            variation: 0,
            inelasticPercentage: 0,
            peakiness: 0,
            maxPrice: 120,
            type: "elastic"
        },
        hydrogen: {},
        firms: {},
        units: [],
        renewable: {
            solar: defaultRenewableId,
            solarTh: defaultRenewableId,
            wind: defaultRenewableId,
            cog: defaultRenewableId,
            others: defaultRenewableId,
            windFixPrice: 0,
            cogFixPrice: 0,
            othersFixPrice: 0,
            solarFixPrice: 0,
            solarThFixPrice: 0
        },
        hydro: {
            peak: "monthly",
            dispatch_id: defaultHydroId,
            flow_id: defaultHydroId,
            dispatchFixPrice: 0,
            flowFixPrice: 0,
            maxFlow: 5000
        },
        fuel:{
            coalId: defaultGasId,
            gasId: defaultGasId,
            taxGenerationId: defaultGasId,
            co2Id: defaultGasId,
            hydrogenId: -1,
            coalFixPrice: 0,
            gasFixPrice: 0,
            co2FixPrice: 0,
            taxGenerationFixPrice: 0,
            hydrogenFixPrice: 0
        },
        interconnectionFixPrice: 0,
        interconnectionId: defaultInterconnectionId,
        maintenanceId: defaultMaintenanceId,
        priceId: undefined,
        technologyGenerationId: undefined,
        strategy: false,
        competitive: false,
        storage: false,
        plants: []
    }), [simulation, defaultGasId, defaultRenewableId, defaultDemandId, defaultInterconnectionId, defaultMaintenanceId, defaultHydroId, defaultMarketStructureId]);


    const [simulationConfig, setSimulationConfig] = useState(defaultSimulationConfig);
    const [saveAsName, setSaveAsName] = useState("");
    const [firmsModalShow, setFirmsModalShow] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const [unitsModalShow, setUnitsModalShow] = useState(false);
    const [showSaveAs, setShowSaveAs] = useState(false);
    const [showWarnings, setShowWarnings] = useState(false);
    const [validateForm, setValidateForm] = useState(false);
    const [status, setStatus] = useState(simulation.status);
    const marketStructureOptions = marketStructureList ? marketStructureList.map(s=><option key={s.value} value={s.value}>{s.label}</option>) : null;
    const demandOptions = dataSeries ? dataSeries.filter(d=>d.type==="demand").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const hydroOptions = dataSeries ? dataSeries.filter(d=>d.type==="hydro").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const fuelOptions = dataSeries ? dataSeries.filter(d=>d.type==="fuel").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const interconnectionOptions = dataSeries ? dataSeries.filter(d=>d.type==="interconnection").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const maintenanceOptions = dataSeries ? dataSeries.filter(d=>d.type==="maintenance").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const renewableOptions = dataSeries ? dataSeries.filter(d=>d.type==="renewable").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(simulation.tag);

    const handleCloseFirmsModal = () => setFirmsModalShow(false);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/tags'))
            .then(res => res.json()).then(data => {
            let tagsArray = [];
            data.list.forEach(t=>{
                if(t.tag !== null) {
                    tagsArray.push({
                        label: t.tag,
                        value: t.tag
                    });
                }
            });
            setTags(tagsArray);
        });
    }, []);

    const updateUnitsFirmsByMarketStructure = (marketStructureId) => {
        trackPromise(
            fetch('/m40alasocho/market/structure/' + marketStructureId))
            .then(res => res.json()).then(data => {
            const firmsData = buildFirmsData(data["companies"]);
            const units = buildUnits(data["units"]);
            setSimulationConfig(simulationConfig => {
                return {
                    ...simulationConfig,
                    firms: firmsData,
                    units: units
                };
            });
        });
    };

    useEffect(() => {
        setStatus(simulation.status);
    }, [simulation.status]);


    useEffect(() => {
        if(simulation.id !== '') {
            setSimulationConfig({
                id: simulation.id,
                name: simulation.name,
                tag: simulation.tag,
                simulationType: simulation.simulationType,
                timePeriod: {...simulation.configuration.timePeriod},
                marketStructureId: simulation.market_structure.id,
                demand:{...simulation.configuration.demand},
                hydrogen:{},
                firms: {...simulation.configuration.firms},
                units: buildUnits([...simulation.configuration.units]),
                hydro: {...simulation.configuration.hydro},
                renewable: {...simulation.configuration.renewable},
                fuel:{
                    ...simulation.configuration.fuel,
                    hydrogenId: -1
                },
                interconnectionFixPrice: simulation.configuration.interconnectionFixPrice,
                interconnectionId: simulation.configuration.interconnectionId,
                maintenanceId: simulation.configuration.maintenanceId,
                priceId: undefined,
                technologyGenerationId: undefined,
                strategy: simulation.configuration.strategy,
                competitive: simulation.configuration.competitive,
                storage: simulation.configuration.storage
            });
        }else{
            setSimulationConfig(defaultSimulationConfig);
            updateUnitsFirmsByMarketStructure(defaultMarketStructureId);
        }
    }, [simulation, defaultSimulationConfig, defaultMarketStructureId]);


    async function startSimulation () {
        updateSimulation({...simulation,
            "status": "RUNNING"})
        setStatus("RUNNING");
        sendNotification('The simulation has started. You will be informed when finished', "success");
        const response = await fetch('/m40alasocho/simulation/' + simulationConfig.id + '/run?type=energeia-simula', {
                method: 'POST' ,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        const json = await response.json();
        if("error" in json){
            sendNotification(json.error, "danger");
            updateSimulation({...simulation,
                             "status": "ERROR"})
            setStatus("ERROR");
        }
        else if("warning" in json){
            sendNotification(json.warning, "warning");
            updateSimulation({...simulation,
                "status": "NEW",
                "warnings": {
                    ...simulation.warnings,
                    "messages": {...JSON.parse(json.messages)}
            }
            })
            setStatus("NEW");
        }
        else {
            sendNotification('Simulation ' + json.simulation.name + ' Finished', "success");
            finishSimulation(json.list, json.simulation);
        }
    }

    const cloneSimulation = () => {
        setShowSaveAs(false);
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationConfig.id + '/clone/' + userId, {
                method: 'POST' ,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: saveAsName
                })
            }))
            .then(res => res.json()).then(data => {
                if("error" in data){
                    sendNotification(data.error, "danger");
                }else {
                    updateSimulationList(data.list);
                    loadSimulation(data.simulation.id);
                    sendNotification('Simulation cloned', "success");
                }
        });
    }

    const validateAndSave = () => {
        let validForm = true;
        let validDates = isValidDatesRange();
        setValidateForm(true);
        if(simulationConfig.name === ''){
            validForm = false;
        }
        if(!validDates){
            sendNotification("Date From can not be after Date To", "warning");
        }
        let validUnits = simulationConfig.units.filter(u=>u.updated && u.invalid).length === 0;
        if(!validForm){
            sendNotification("Please check missing fields", "warning");
        }
        if(!validUnits){
            sendNotification("Please check wrong units dates On/Off", "warning");
        }
        if(validUnits && validForm && validDates){
            console.log(simulationConfig);
            trackPromise(
                fetch('/m40alasocho/simulation/user/' + userId + '/settings', {
                    method: 'POST' ,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({...simulationConfig,
                        units: simulationConfig.units.filter(u=>u.updated)
                    })
                }))
                .then(res => res.json()).then(data => {
                    if("error" in data){
                        sendNotification(data.error, "danger");
                    }else{
                        updateSimulationList(data.list);
                        updateSimulation(data.simulation);
                        history.push('/simulations/' + data.simulation.id);
                        sendNotification('Simulation Settings Saved', "success");
                    }
            });
        }
    };

    let selectOpts = {};
    if(selectedTag){
        selectOpts = {
            value: {
                value: selectedTag, label: selectedTag
            }
        }
    }

    let hours = [];
    for(let i = 0; i <24; i++){
        hours.push(i);
    }
    const hoursOptions = hours.map(h=>
        <option key={h} value={h}>{h}h</option>
    );

    const isValidDatesRange = () => {
        if(simulationConfig.timePeriod.mode === 'General'){
            const dateFrom = new Date(simulationConfig.timePeriod.dateFrom);
            const dateTo = new Date(simulationConfig.timePeriod.dateTo);
            return dateFrom < dateTo;
        }
        return true;
    }

    let timePeriodByOption = null;
    if(simulationConfig.timePeriod.mode === "General"){
        timePeriodByOption = (
            <Row>
                <Col sm={3}>
                    <Form.Label>Date From
                        <Form.Control type="date" placeholder="Date From" size="sm"
                                      isInvalid={validateForm && simulationConfig.timePeriod.dateFrom === ''}
                                      value={simulationConfig.timePeriod.dateFrom}
                                      onChange={event=>{
                                        setSimulationConfig({
                                            ...simulationConfig, timePeriod: {
                                                ...simulationConfig.timePeriod,
                                                dateFrom: event.target.value
                                            }
                                        });
                        }}/>
                    </Form.Label>
                </Col>
                <Col sm={3}>
                    <Form.Label>Date To
                        <Form.Control type="date" placeholder="Date To" size="sm"
                                      isInvalid={validateForm && simulationConfig.timePeriod.dateTo === ''}
                                      value={simulationConfig.timePeriod.dateTo}
                                      onChange={event=>{
                                        setSimulationConfig({
                                            ...simulationConfig, timePeriod: {
                                                ...simulationConfig.timePeriod,
                                                dateTo: event.target.value
                                            }
                                        });
                        }}/>
                    </Form.Label>
                </Col>
            </Row>
        );
    }
    else if(simulationConfig.timePeriod.mode === "Hourly"){
        timePeriodByOption = (
            <Row>
                <Col sm={3}>
                    <Form.Label>Date
                        <Form.Control type="date" placeholder="Date" size="sm" isInvalid={validateForm && simulationConfig.timePeriod.dateFrom === ''} value={simulationConfig.timePeriod.dateFrom} onChange={event=>{
                            setSimulationConfig({...simulationConfig, timePeriod: {
                                    ...simulationConfig.timePeriod,
                                    dateFrom: event.target.value
                                }});
                        }}/>
                    </Form.Label>
                </Col>
                <Col sm={3}>
                    <Form.Label>Hour
                        <Form.Control as="select" aria-label="Hour" size="sm" value={simulationConfig.timePeriod.hour} onChange={event=>{
                            setSimulationConfig({...simulationConfig, timePeriod: {
                                    ...simulationConfig.timePeriod,
                                    hour: event.target.value
                                }});
                        }}>
                            {hoursOptions}
                        </Form.Control>
                    </Form.Label>
                </Col>
            </Row>
        );
    }
    else if(simulationConfig.timePeriod.mode === "Daily"){
        timePeriodByOption = (
            <Row>
                <Col sm={3}>
                    <Form.Label>Date
                        <Form.Control type="date" placeholder="Date" size="sm" isInvalid={validateForm && simulationConfig.timePeriod.dateFrom === ''}  value={simulationConfig.timePeriod.dateFrom} onChange={event=>{
                            setSimulationConfig({...simulationConfig, timePeriod: {
                                    ...simulationConfig.timePeriod,
                                    dateFrom: event.target.value
                                }});
                        }}/>
                      </Form.Label>
                </Col>
            </Row>
        );
    }
    else if(simulationConfig.timePeriod.mode === "Monthly"){
        timePeriodByOption = (
            <Row>
                <Col sm={4}>
                    <Form.Label>Month
                        <Form.Control type="month" placeholder="Date" size="sm" isInvalid={validateForm && simulationConfig.timePeriod.month === ''}  value={simulationConfig.timePeriod.month} onChange={event=>{
                            setSimulationConfig({...simulationConfig, timePeriod: {
                                    ...simulationConfig.timePeriod,
                                    month: event.target.value
                                }});
                        }}/>
                     </Form.Label>
                </Col>
            </Row>
        );
    }
    else if(simulationConfig.timePeriod.mode === "Yearly"){
        timePeriodByOption = (
            <Row>
                <Col sm={2}>
                    <Form.Label>Year
                        <Form.Control type="number" min={2000} placeholder="Year" size="sm" isInvalid={validateForm && simulationConfig.timePeriod.year === ''}  value={simulationConfig.timePeriod.year} onChange={event=>{
                            setSimulationConfig({...simulationConfig, timePeriod: {
                                    ...simulationConfig.timePeriod,
                                    year: event.target.value
                                }});
                        }}/>
                    </Form.Label>
                </Col>
            </Row>
        );
    }

    let demandElasticOptions = null;
    if(simulationConfig.demand.type === "elastic"){
        demandElasticOptions = (
            <Row>
                <Col sm={6}>
                    <Form.Label>% elastic
                        <Form.Control type="number" placeholder="% elastic" isInvalid={validateForm && simulationConfig.demand.inelasticPercentage === ''}
                                        min={0} max={100}
                                      size="sm" value={simulationConfig.demand.inelasticPercentage} onChange={event=>{
                                          if(event.target.value >= 0 && event.target.value <= 100) {
                                              setSimulationConfig({
                                                  ...simulationConfig, demand: {
                                                      ...simulationConfig.demand,
                                                      inelasticPercentage: event.target.value
                                                  }
                                              });
                                          }
                        }}/>
                    </Form.Label>
                </Col>
            </Row>
        );
    }else{
        demandElasticOptions = null;
    }

    const firmsContract = simulationConfig.firms ? (
        <Table bordered hover size="sm">
            <thead>
                <tr>
                    <th>Contracts</th>
                    <th>Contract Price (€/MWh)</th>
                    <th>Contract Quantity (MWh)</th>
                    <th>Strategic Behaviour</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(simulationConfig.firms).map(k=>{
                    return <tr key={k}>
                        <td>{k}</td>
                        <td>
                            <Form.Control type="number" min={0} style={{width:"50%"}} placeholder="Base Price (€/MWh)" size="sm" value={simulationConfig.firms[k].price} onChange={event=>{
                                if(event.target.value >= 0) {
                                    setSimulationConfig({
                                        ...simulationConfig, firms: {
                                            ...simulationConfig.firms,
                                            [k]: {
                                                ...simulationConfig.firms[k],
                                                price: event.target.value
                                            }
                                        }
                                    });
                                }
                            }}/>
                        </td>
                        <td>
                            <Form.Control type="number" min={0} style={{width:"50%"}} placeholder="Base Quantity (MWh)" size="sm" value={simulationConfig.firms[k].quantity} onChange={event=>{
                                if(event.target.value >= 0) {
                                    setSimulationConfig({
                                        ...simulationConfig, firms: {
                                            ...simulationConfig.firms,
                                            [k]: {
                                                ...simulationConfig.firms[k],
                                                quantity: event.target.value
                                            }
                                        }
                                    });
                                }
                            }}/>
                        </td>
                        <td>
                            <Form.Check className="float-right" type="checkbox" size="sm" checked={simulationConfig.firms[k].strategy} onChange={event=>{
                                setSimulationConfig({...simulationConfig, firms: {
                                        ...simulationConfig.firms,
                                        [k]: {
                                            ...simulationConfig.firms[k],
                                            strategy: event.target.checked
                                        }}});
                            }}/>
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
    ) : null;

    const unitsOnOff = simulationConfig.units ? (
        <div style={{width: "100%", height: "300px", overflow: "auto"}}>
            <Table bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Unit</th>
                        <th>Technology</th>
                        <th>Firm</th>
                        <th>On</th>
                        <th>Off</th>
                    </tr>
                </thead>
                <tbody>
                {simulationConfig.units.map((u, i)=>{
                    return <tr key={i}>
                        <td>{u.code}</td>
                        <td>{u.technology}</td>
                        <td>{u.company}</td>
                        <td>
                            <Form.Control type="date" size="sm" value={u.dateOn} isInvalid={u.updated && validateForm && u.invalid} onChange={event=>{
                                const units = [...simulationConfig.units];
                                units[u.key-1] = {
                                    ...units[u.key-1],
                                    dateOn: event.target.value,
                                    updated: true,
                                    invalid: invalidUnitDates(event.target.value, u.dateOff)
                                };
                                setSimulationConfig({...simulationConfig, units: [
                                        ...units
                                    ]
                                });
                            }}/>
                        </td>
                        <td>
                            <Form.Control type="date" size="sm" value={u.dateOff} isInvalid={u.updated && validateForm && u.invalid} onChange={event=>{
                                const units = [...simulationConfig.units];
                                units[u.key-1] = {
                                    ...units[u.key-1],
                                    dateOff: event.target.value,
                                    updated: true,
                                    invalid: invalidUnitDates(u.dateOn, event.target.value)
                                };
                                setSimulationConfig({...simulationConfig, units: [
                                        ...units
                                    ]
                                });
                            }}/>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
        </div>
    ) : null;

    const warningsMessages = (simulation && simulation.warnings) ? simulation.warnings.messages : null;

    const coalFixPrice = simulationConfig.fuel.coalId === 0 ? (
        <Form.Label>Coal
            <Form.Control type="number" placeholder="Coal" size="sm" isInvalid={validateForm && simulationConfig.fuel.coalFixPrice === ''}
                            min={0} max={100}
                          value={simulationConfig.fuel.coalFixPrice} onChange={event=>{
                              if(event.target.value >= 0 && event.target.value <= 100) {
                                  setSimulationConfig({
                                      ...simulationConfig, fuel: {
                                          ...simulationConfig.fuel,
                                          coalFixPrice: event.target.value
                                      }
                                  });
                              }
            }}/>
        </Form.Label>
    ) : null;

    const co2FixPrice = simulationConfig.fuel.co2Id === 0 ? (
        <Form.Label>CO2
            <Form.Control type="number" placeholder="CO2" size="sm" isInvalid={validateForm && simulationConfig.fuel.co2FixPrice === ''}
                          min={0} max={100}
                          value={simulationConfig.fuel.co2FixPrice} onChange={event=>{
                if(event.target.value >= 0 && event.target.value <= 100) {
                    setSimulationConfig({
                        ...simulationConfig, fuel: {
                            ...simulationConfig.fuel,
                            co2FixPrice: parseInt(event.target.value)
                        }
                    });
                }
            }}/>
        </Form.Label>
    ) : null;

    const gasFixPrice = simulationConfig.fuel.gasId === 0 ? (
        <Form.Label>Gas
            <Form.Control type="number" placeholder="Gas" size="sm" isInvalid={validateForm && simulationConfig.fuel.gasFixPrice === ''}
                        min={0} max={100}
                          value={simulationConfig.fuel.gasFixPrice} onChange={event=>{
                if(event.target.value >= 0 && event.target.value <= 100) {
                    setSimulationConfig({
                        ...simulationConfig, fuel: {
                            ...simulationConfig.fuel,
                            gasFixPrice: event.target.value
                        }
                    });
                }
            }}/>
        </Form.Label>
    ) : null;

    const taxGenerationFixPrice = simulationConfig.fuel.taxGenerationId === 0 ? (
        <Form.Label>Tax Generation
            <Form.Control type="number" placeholder="Tax Generation" size="sm" isInvalid={validateForm && simulationConfig.fuel.taxGenerationFixPrice === ''}
            min={0} max={100}
                          value={simulationConfig.fuel.taxGenerationFixPrice} onChange={event=>{
                if(event.target.value >= 0 && event.target.value <= 100) {
                    setSimulationConfig({
                        ...simulationConfig, fuel: {
                            ...simulationConfig.fuel,
                            taxGenerationFixPrice: event.target.value
                        }
                    });
                }
            }}/>
        </Form.Label>
    ) : null;

    const interconnectionFixPrice = simulationConfig.interconnectionId === 0 ? (
        <Form.Label>Interconnection
            <Form.Control type="number" min={0} max={100} placeholder="Interconnection" size="sm"
                          isInvalid={validateForm && simulationConfig.interconnectionFixPrice === ''} value={simulationConfig.interconnectionFixPrice} onChange={event=>{
                if(event.target.value >= 0 && event.target.value <= 100) {
                    setSimulationConfig({
                        ...simulationConfig,
                        "interconnectionFixPrice": event.target.value
                    });
                }
            }}/>
        </Form.Label>
    ) : null;

    const hydroDispatchFix = simulationConfig.hydro.dispatch_id === 0 ? (
        <Form.Label>Hydro Dispatch
            <Form.Control type="number" min={0} placeholder="Hydro Dispatch" isInvalid={validateForm && simulationConfig.hydro.dispatchFixPrice === ''} size="sm" value={simulationConfig.hydro.dispatchFixPrice} onChange={event=>{
                setSimulationConfig({...simulationConfig, hydro: {
                        ...simulationConfig.hydro,
                        dispatchFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const hydroFlowFix = simulationConfig.hydro.flow_id === 0 ? (
        <Form.Label>Hydro Flow
            <Form.Control type="number" placeholder="Hydro Flow" isInvalid={validateForm && simulationConfig.hydro.flowFixPrice === ''} size="sm" value={simulationConfig.hydro.flowFixPrice} onChange={event=>{
                setSimulationConfig({...simulationConfig, hydro: {
                        ...simulationConfig.hydro,
                        flowFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const renewableWindFix = simulationConfig.renewable.wind === 0 ? (
        <Form.Label>Wind
            <Form.Control type="number" placeholder="Wind" isInvalid={validateForm && simulationConfig.renewable.windFixPrice === ''} size="sm" value={simulationConfig.renewable.windFixPrice} onChange={event=>{
                setSimulationConfig({...simulationConfig, renewable: {
                        ...simulationConfig.renewable,
                        windFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const hydroFlow = simulationConfig.hydro.peak === 'monthly' || simulationConfig.hydro.peak === 'yearly' ? (
        <Form.Label>Max Flow (MWh)
            <Form.Control type="number" placeholder="Max Flow" isInvalid={validateForm && simulationConfig.hydro.maxFlow === ''}
                          min={0} size="sm" value={simulationConfig.hydro.maxFlow}
                          onChange={event=>{
                              if(event.target.value >= 0) {
                                  setSimulationConfig({
                                      ...simulationConfig, hydro: {
                                          ...simulationConfig.hydro,
                                          maxFlow: event.target.value
                                      }
                                  });
                              }
                          }}/>
        </Form.Label>
    ) : null;

    const renewableSolarFix = simulationConfig.renewable.solar === 0 ? (
        <Form.Label>Solar
            <Form.Control type="number" placeholder="Solar" isInvalid={validateForm && simulationConfig.renewable.solarFixPrice === ''} size="sm" value={simulationConfig.renewable.solarFixPrice} onChange={event=>{
                setSimulationConfig({...simulationConfig, renewable: {
                        ...simulationConfig.renewable,
                        solarFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const renewableOthersFix = simulationConfig.renewable.others === 0 ? (
        <Form.Label>Others
            <Form.Control type="number" placeholder="Others" isInvalid={validateForm && simulationConfig.renewable.othersFixPrice === ''} size="sm" value={simulationConfig.renewable.othersFixPrice} onChange={event=>{
                setSimulationConfig({...simulationConfig, renewable: {
                        ...simulationConfig.renewable,
                        othersFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const renewableCogFix = simulationConfig.renewable.cog === 0 ? (
        <Form.Label>Cogeneration
            <Form.Control type="number" placeholder="Cogeneration" isInvalid={validateForm && simulationConfig.renewable.cogFixPrice === ''} size="sm" value={simulationConfig.renewable.cogFixPrice} onChange={event=>{
                setSimulationConfig({...simulationConfig, renewable: {
                        ...simulationConfig.renewable,
                        cogFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const renewableSolarThFix = simulationConfig.renewable.solarTh === 0 ? (
        <Form.Label>Solar Thermal
            <Form.Control type="number" placeholder="Solar Thermal" isInvalid={validateForm && simulationConfig.renewable.solarThFixPrice === ''} size="sm" value={simulationConfig.renewable.solarThFixPrice} onChange={event=>{
                setSimulationConfig({...simulationConfig, renewable: {
                        ...simulationConfig.renewable,
                        solarThFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const simulationDate = simulation.id !== "" ? ((status === 'NEW' || status === 'RUNNING' || status === 'ERROR') ? (<Col sm={3}>
            <Row>
                <Col sm={12}>
                    <b>Creation Date: </b>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    {simulation.creation_date.join(" ")}
                </Col>
            </Row>
        </Col>) :
        (<Col sm={3}>
            <Row>
                <Col sm={12}>
                    <b>Simulation Date: </b>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    {simulation.simulation_date.join(" ")}
                </Col>
            </Row>
        </Col>)) : null;


    let buttonsBySimulation = null;
    if(simulation.status === 'RUNNING' || status === 'RUNNING'){
        const simDate = new Date(simulation.creation_date[0]);
        const diffDays = Math.floor((new Date() - simDate) / 86400000); // days
        buttonsBySimulation = diffDays < 1 ? (
                                <div>
                                    <button onClick={()=>{
                                        sendNotification("The simulation is still running. You will be notified when it finishes", "warning");
                                    }} style={{marginLeft:"5px"}} className="btn btn-sm btn-warning float-right">
                                        Running...
                                    </button>
                                    <button type="button" className="btn btn-sm btn-outline-primary float-right"
                                            style={{marginLeft:"5px"}}  id={simulation.id}
                                            onClick={() => setShowLogs(!showLogs)}
                                    >
                                        Progress
                                    </button>
                                </div>)
            :
            (
                (
                    <div>
                        <button onClick={()=>{
                            sendNotification("The simulation is still running. You will be notified when it finishes", "warning");
                        }} style={{marginLeft:"5px"}} className="btn btn-sm btn-warning float-right">
                            Running...
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-primary float-right"
                                style={{marginLeft:"5px"}}  id={simulation.id}
                                onClick={() => setShowLogs(!showLogs)}
                        >
                            Progress
                        </button>
                        <button style={{marginLeft:"5px"}}
                                id={simulation.id}
                                onClick={deleteSimulationModal}
                                className="btn btn-sm btn-outline-danger float-right">
                            Delete
                        </button>
                    </div>)
            )
    }else if(simulation.status === 'NEW'){
        buttonsBySimulation = <div>
                                <button type="button" className="btn btn-sm btn-outline-danger float-right" style={{marginLeft:"5px"}} onClick={deleteSimulationModal}  id={simulation.id}>Delete</button>
                                <button onClick={cancelSimulationChanges} style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-secondary float-right">
                                    Cancel
                                </button>
                                <button onClick={startSimulation} style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-success float-right">
                                    Simulate
                                </button>
                                <button onClick={validateAndSave} style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-primary float-right">
                                    Save
                                </button>
                                <button onClick={() => {
                                    setSaveAsName(simulationConfig.name + "Clone");
                                    setShowSaveAs(true);
                                }} className="btn btn-sm btn-outline-primary float-right"
                                        style={{marginLeft:"5px"}} >
                                    Save as
                                </button>
                                {simulation.warnings && <button onClick={() => {
                                    setShowWarnings(true);
                                }} className="btn btn-sm btn-outline-warning float-right">
                                    Warnings
                                </button>}
                            </div>
    }else if(simulation.status === 'ERROR' || status === 'ERROR'){
        buttonsBySimulation = <div>
                                <button style={{marginLeft:"5px"}}
                                        id={simulation.id}
                                        onClick={deleteSimulationModal}
                                        className="btn btn-sm btn-outline-danger float-right">
                                    Delete
                                </button>
                                <button type="button" className="btn btn-sm btn-outline-primary float-right"
                                        style={{marginLeft:"5px"}}  id={simulation.id}
                                        onClick={() => setShowLogs(!showLogs)}
                                >
                                    Progress
                                </button>
                            </div>
    }

    const simulationLegend = simulation.id !== "" ? (<Alert variant="primary" style={{marginTop:"10px", width: "100%"}}>
        <Row>
            <Col sm={8}>
                <Row>
                    <Col sm={5}>
                        <Row>
                            <Col sm={12}>
                                <b>Market Structure:</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {simulation.market_structure.filename}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4}>
                        <Row>
                            <Col sm={12}>
                                <b>Name: </b>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {simulation.name}
                            </Col>
                        </Row>
                    </Col>
                    {simulationDate}
                </Row>
            </Col>
            {!readOnly && <Col sm={4}>
                {buttonsBySimulation}
            </Col>
            }
        </Row>
    </Alert>) : <Alert variant="primary" style={{marginTop:"10px", width: "98%"}}>
        <Row>
            <Col sm={9}>
                Create Simulation
            </Col>
            <Col sm={3}>
                <button onClick={cancelSimulationChanges} style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-secondary float-right">
                    Cancel
                </button>
                <button onClick={validateAndSave} className="btn btn-sm btn-outline-primary float-right">
                    Save
                </button>
            </Col>
        </Row>
    </Alert>;



    return simulationConfig ? (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            {simulationLegend}
            <Form>
                <HorizontalLine color="#4a92db" size={3} tittle="Info" icon={<SiMarketo/>}/>
                <Row>
                    <Col sm={4}>
                        <Form.Label>Market Structure
                            <Form.Control as="select" aria-label="Market Structure" size="sm" value={simulationConfig.marketStructureId} onChange={event=>{
                                setSimulationConfig({...simulationConfig, "marketStructureId": parseInt(event.target.value)});
                                updateUnitsFirmsByMarketStructure(parseInt(event.target.value));
                            }}>
                                {marketStructureOptions}
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Name
                            <Form.Control type="text" placeholder="Simulation Name" isInvalid={validateForm && simulationConfig.name === ''} size="sm" value={simulationConfig.name} onChange={event=>{
                                setSimulationConfig({...simulationConfig, "name":event.target.value});
                            }}/>
                        </Form.Label>
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Tag
                            <CreatableSelect isClearable
                                             name={"tag"}
                                             {...selectOpts}
                                             options={tags}
                                             placeholder={"Select" +
                                                 " or create a Tag for this simulation"}
                                             onChange={newValue => {
                                                 setSelectedTag(newValue.value);
                                                 setSimulationConfig({...simulationConfig, "tag": newValue.value})
                                             }}
                            />
                        </Form.Label>
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Time Period" icon={<GoCalendar/>}/>
                <Row>
                    <Col sm={2}>
                        <Form.Label>Period
                            <Form.Control as="select" aria-label="Period" size="sm" value={simulationConfig.timePeriod.mode} onChange={event=>{
                                setSimulationConfig({...simulationConfig, timePeriod: {
                                        ...simulationConfig.timePeriod,
                                        mode: event.target.value
                                    }});
                            }}>
                                <option value="General">General</option>
                                <option value="Daily">Daily</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly">Yearly</option>
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    <Col sm={8}>
                        {timePeriodByOption}
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Demand" icon={<GiElectric/>}/>
                <Row>
                    <Col>
                        <Form.Label>Demand Data (GWh)
                            <Form.Control as="select" aria-label="Demand" size="sm" value={simulationConfig.demand.id} onChange={event=>{
                                setSimulationConfig({...simulationConfig, demand: {
                                        ...simulationConfig.demand,
                                        id: event.target.value
                                    }});
                            }}>
                                {demandOptions}
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Label>Peakiness (%)
                            <Form.Control type="number" placeholder="Peakiness (%)" isInvalid={validateForm && simulationConfig.demand.peakiness === ''}
                            min={-100} max={100} size="sm" value={simulationConfig.demand.peakiness} onChange={event=>{
                                    if(event.target.value <= 100 && event.target.value >= -100) {
                                        setSimulationConfig({
                                            ...simulationConfig, demand: {
                                                ...simulationConfig.demand,
                                                peakiness: event.target.value
                                            }
                                        });
                                    }
                            }}/>
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Label>Change (%)
                            <Form.Control type="number" placeholder="Change (%)" isInvalid={validateForm && simulationConfig.demand.variation === ''} size="sm" value={simulationConfig.demand.variation} onChange={event=>{
                                setSimulationConfig({...simulationConfig, demand: {
                                        ...simulationConfig.demand,
                                        variation: event.target.value
                                    }});
                            }}/>
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Label>Max Price (€/MWh)
                            <Form.Control type="number" placeholder="Max Price" isInvalid={validateForm && simulationConfig.demand.maxPrice === ''}
                                          min={0}
                                          size="sm" value={simulationConfig.demand.maxPrice} onChange={event=>{
                                              if(event.target.value >= 0) {
                                                  setSimulationConfig({
                                                      ...simulationConfig, demand: {
                                                          ...simulationConfig.demand,
                                                          maxPrice: event.target.value
                                                      }
                                                  });
                                              }
                            }}/>
                        </Form.Label>
                    </Col>
                    <Col sm={1}>
                        <Form.Label>Type
                            <Form.Control as="select" aria-label="Period" size="sm" value={simulationConfig.demand.type} onChange={event=>{
                                setSimulationConfig({...simulationConfig, demand: {
                                        ...simulationConfig.demand,
                                        type: event.target.value
                                    }});
                            }}>
                                <option value="elastic">Elastic</option>
                                <option value="inelastic">Inelastic</option>
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    <Col>
                        {demandElasticOptions}
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Firms & Units" icon={<GrTechnology/>}/>
                <Row>
                    <Col>
                        <Form.Label>Maintenance
                            <Form.Control as="select" aria-label="Maintenance" size="sm" value={simulationConfig.maintenanceId} onChange={event=>{
                                setSimulationConfig({...simulationConfig, "maintenanceId":event.target.value});
                            }}>
                                {maintenanceOptions}
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    <Col>
                        <button type="button" onClick={(event)=>{
                            event.preventDefault();
                            setFirmsModalShow(true);
                        }} className="btn btn-outline-primary">
                            <BsTable/> Contracts
                        </button>
                    </Col>
                    <Col>
                        <button type="button" onClick={(event)=>{
                            event.preventDefault();
                            setUnitsModalShow(true);
                        }} className="btn btn-outline-primary">
                            <BsTable/> Units On/Off
                        </button>
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Fuel Prices" icon={<FaGasPump/>}/>
                <Row>
                    <Col>
                        <Form.Label>Coal (€/MWht)
                            <Form.Control as="select" aria-label="Coal" size="sm" value={simulationConfig.fuel.coalId} onChange={event=>{
                                setSimulationConfig({...simulationConfig, fuel: {
                                        ...simulationConfig.fuel,
                                        coalId: parseInt(event.target.value)
                                    }});
                            }}>
                                {fuelOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {coalFixPrice}
                    </Col>
                    <Col>
                        <Form.Label>Gas (€/MWht)
                            <Form.Control as="select" aria-label="Gas" size="sm" value={simulationConfig.fuel.gasId} onChange={event=>{
                                setSimulationConfig({...simulationConfig, fuel: {
                                        ...simulationConfig.fuel,
                                        gasId: parseInt(event.target.value)
                                    }});
                            }}>
                                {fuelOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {gasFixPrice}
                    </Col>
                    <Col>
                        <Form.Label>CO2 (€/Ton)
                            <Form.Control as="select" aria-label="Co2" size="sm" value={simulationConfig.fuel.co2Id} onChange={event=>{
                                setSimulationConfig({...simulationConfig, fuel: {
                                        ...simulationConfig.fuel,
                                        co2Id: parseInt(event.target.value)
                                    }});
                            }}>
                                {fuelOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {co2FixPrice}
                    </Col>
                    <Col>
                        <Form.Label>Tax Generation (%)
                            <Form.Control as="select" aria-label="Tax Generation" size="sm" value={simulationConfig.fuel.taxGenerationId} onChange={event=>{
                                setSimulationConfig({...simulationConfig, fuel: {
                                        ...simulationConfig.fuel,
                                        taxGenerationId: parseInt(event.target.value)
                                    }});
                            }}>
                                {fuelOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {taxGenerationFixPrice}
                    </Col>
                    <Col>
                        <Form.Label>Interconnection prices (€/MWh)
                            <Form.Control as="select" aria-label="Interconnections" size="sm" value={simulationConfig.interconnectionId} onChange={event=>{
                                setSimulationConfig({...simulationConfig, "interconnectionId": parseInt(event.target.value)});
                            }}>
                                {interconnectionOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {interconnectionFixPrice}
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Hydro" icon={<BiWater/>}/>
                <Row>
                    <Col sm={2}>
                        <Form.Label>Peak Shaving
                            <Form.Control as="select" aria-label="Hydro" size="sm" value={simulationConfig.hydro.peak} onChange={event=>{
                                setSimulationConfig({...simulationConfig, hydro: {
                                        ...simulationConfig.hydro,
                                        peak: event.target.value
                                    }});
                            }}>
                                <optgroup label="Yes">
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </optgroup>
                                <optgroup label="No">
                                    <option value="real">Real</option>
                                </optgroup>
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    {
                        simulationConfig.hydro.peak !== 'real' &&
                        <Col sm={2}>
                            {hydroFlow}
                        </Col>
                    }
                    <Col sm={4}>
                        <Form.Label>Dispatchable Hydro (MWh)
                            <Form.Control as="select" aria-label="Hydro" size="sm" value={simulationConfig.hydro.dispatch_id} onChange={event=>{
                                setSimulationConfig({...simulationConfig, hydro: {
                                        ...simulationConfig.hydro,
                                        dispatch_id: parseInt(event.target.value)
                                    }});
                            }}>
                                {hydroOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {hydroDispatchFix}
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Run of River Hydro (MWh)
                            <Form.Control as="select" aria-label="Hydro" size="sm" value={simulationConfig.hydro.flow_id} onChange={event=>{
                                setSimulationConfig({...simulationConfig, hydro: {
                                        ...simulationConfig.hydro,
                                        flow_id: parseInt(event.target.value)
                                    }});
                            }}>
                                {hydroOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {hydroFlowFix}
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Renewables availability" icon={<FaRecycle/>}/>
                <Row>
                    <Col>
                        <Form.Label>Wind (%)
                            <Form.Control as="select" aria-label="Eolic" size="sm" value={simulationConfig.renewable.wind} onChange={event=>{
                                setSimulationConfig({...simulationConfig, renewable: {
                                        ...simulationConfig.renewable,
                                        wind: parseInt(event.target.value)
                                    }});
                            }}>
                                {renewableOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {renewableWindFix}
                    </Col>
                    <Col>
                        <Form.Label>Solar (%)
                            <Form.Control as="select" aria-label="Solar" size="sm" value={simulationConfig.renewable.solar} onChange={event=>{
                                setSimulationConfig({...simulationConfig, renewable: {
                                        ...simulationConfig.renewable,
                                        solar: parseInt(event.target.value)
                                    }});
                            }}>
                                {renewableOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {renewableSolarFix}
                    </Col>
                    <Col>
                        <Form.Label>Solar Thermal (%)
                            <Form.Control as="select" aria-label="Solar Thermal" size="sm" value={simulationConfig.renewable.solarTh} onChange={event=>{
                                setSimulationConfig({...simulationConfig, renewable: {
                                        ...simulationConfig.renewable,
                                        solarTh: parseInt(event.target.value)
                                    }});
                            }}>
                                {renewableOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {renewableSolarThFix}
                    </Col>
                    <Col>
                        <Form.Label>Others (%)
                            <Form.Control as="select" aria-label="Others" size="sm" value={simulationConfig.renewable.others} onChange={event=>{
                                setSimulationConfig({...simulationConfig, renewable: {
                                        ...simulationConfig.renewable,
                                        others: parseInt(event.target.value)
                                    }});
                            }}>
                                {renewableOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {renewableOthersFix}
                    </Col>
                    <Col>
                        <Form.Label>Cogeneration (%)
                            <Form.Control as="select" aria-label="Cogeneration" size="sm" value={simulationConfig.renewable.cog} onChange={event=>{
                                setSimulationConfig({...simulationConfig, renewable: {
                                        ...simulationConfig.renewable,
                                        cog: parseInt(event.target.value)
                                    }});
                            }}>
                                {renewableOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {renewableCogFix}
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Simulation" icon={<GrServerCluster/>}/>
                <Row style={{marginBottom: "100px"}}>
                    <Col sm={2}>
                        <Form.Group className="float-left">
                            <Form.Check type="checkbox" label="Strategic" checked={simulationConfig.strategy} onChange={event=>{
                                setSimulationConfig({...simulationConfig,
                                    strategy: event.target.checked
                                });
                            }}/>
                        </Form.Group>
                    </Col>
                    <Col sm={2}>
                        <Form.Group className="float-left">
                            <Form.Check type="checkbox" label="Storage" checked={simulationConfig.storage} onChange={event=>{
                                setSimulationConfig({...simulationConfig,
                                    storage: event.target.checked
                                });
                            }}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Modal show={firmsModalShow} size="lg" onHide={handleCloseFirmsModal} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Contracts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {firmsContract}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFirmsModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={unitsModalShow} size="xl" onHide={()=>{
                setUnitsModalShow(false);
            }} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Units On/Off</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {unitsOnOff}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{
                        setUnitsModalShow(false);
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showSaveAs} onHide={()=>{
                setShowSaveAs(false)
            }} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Save as</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Name
                        <Form.Control type="text" placeholder="Simulation Name" isInvalid={saveAsName === ''} size="sm" value={saveAsName} onChange={event=>{
                            setSaveAsName(event.target.value);
                        }}/>
                    </Form.Label>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{
                        setShowSaveAs(false)
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={cloneSimulation}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                showLogs &&
                <Row>
                    <Modal show={showLogs} size={"lg"} onHide={()=>{
                        setShowLogs(false)
                    }} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Simulation progress</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SimulationProgressMessages simulationId={simulation.id}
                                                        userId={username}
                                                        isStrategy={simulation.configuration.strategy} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{
                                setShowLogs(false)
                            }}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            }
            <Modal show={showWarnings} onHide={()=>{
                setShowWarnings(false)
            }} animation={false} size={"xl"}>
                <Modal.Header closeButton>
                    <Modal.Title>Warnings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SimulationWarnings messages={warningsMessages} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{
                        setShowWarnings(false)
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </ErrorBoundary>
    ) : null;
}

export default SimulationConfigForm;